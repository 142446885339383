import React, { useLayoutEffect } from 'react';

import {
  FooterContainer,
  FooterContent,
  CreatedContainer,
  TextBold,
  ButtonStyled,
  ButtonStyledCoffee,
  StyledImg,
  // StyledNewsletter,
} from './Footer.styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconLink } from '../shared/IconLink';
import fb from '../../assets/images/fb.png';
import insta from '../../assets/images/insta.png';
import coffe from '../../assets/images/bmc-logo-no-background.png';
import { Link } from 'react-router-dom';

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePolicyButton = () => {
    window.scrollTo(0, 0);
    navigate('/policy');
  };

  // <div>
  //   {/* <Newsletter /> */}
  //   <StyledNewsletter src="https://cdn.forms-content-1.sg-form.com/5f041dab-4ff5-11ef-b618-ce941fc87fb6" />
  // </div>;
  return (
    <FooterContainer>
      <FooterContent>
        <div>
          <ButtonStyled type="button" onClick={handlePolicyButton}>
            {t('policy.title')}
          </ButtonStyled>
        </div>
        <div>
          <IconLink
            href="https://www.instagram.com/p/C9-MiztogSO/?igsh=MXd3c2dseG14aHlyMQ%3D%3D"
            imgSrc={insta}
            altText="Instagram link"
          />
          <IconLink
            href="https://www.facebook.com/people/Hike-Matters/61564045714573/"
            imgSrc={fb}
            altText="Facebook link"
          />
        </div>
        <Link
          to="https://www.buymeacoffee.com/hikematters"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonStyledCoffee>
            <StyledImg src={coffe} alt="coffee icon" className="h-6 w-auto" />
            Buy us a coffee
          </ButtonStyledCoffee>
        </Link>
        <div>
          <TextBold>{t('footer.email')}</TextBold>
        </div>
      </FooterContent>
      <CreatedContainer>2024 Created by KHP</CreatedContainer>
    </FooterContainer>
  );
};
